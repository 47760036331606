<template>
    <div class="bg-white">
        <div v-if="isOkay == false">
            <h3>
                Paste the Google Auth code below
            </h3>
            <Verify :oauth="true" @validated="validated" />
        </div>
        <div v-else>
            <h3>Copy the private key</h3>
            <Copy colored="true" :address="pvKey" class="mt-3"/>
            <div class="text-right mt-3">
                <b-button variant="primary" class="btn-lg" @click="$emit('close')">
                    Okay, close it
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['wallet_id'],
    data () {
        return {
            loading: false,
            isOkay: false,
            form: {
                id: null,
                code: null
            },
            pvKey: null
        }
    },
    created () {
        this.loading = true
        this.form.id = this.wallet_id
        this.sendValidation().then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions('auth', ['sendValidation']),
        ...mapActions('wallet',['validateCode']),
        checkCode () {
            this.loading = true
            this.validateCode(this.form).then(response => {
                this.pvKey = response
                this.isOkay = true
                this.loading = false
            })
        },
        validated() {
            this.isOkay = true
            this.checkCode()
        }
    }
}
</script>