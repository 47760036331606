<template>
    <div>
        <div v-if="loading == false">
            <div class="row text-center">
                <div class="col-sm-4 p-3 click" v-for="asset in assets" :key="asset.id" @click="selectAsset(asset)">
                    <b-card class="bg-white text-black" img-top :class="form.currency == asset.currency ? 'active' : '' ">
                        <img :src="asset.image" alt="" class="w-100" style="border-radius:10px; max-height:100px">
                        <b-card-text>
                            <div class="text-center text-black">
                                {{ asset.name }}
                            </div>
                        </b-card-text>
                    </b-card>
                </div>
            </div>
        </div>
        <Loading v-else />
        <div class="text-right mt-3 row">
            <div class="col-6">
                <b-button variant="link text-black" size="lg" class="mr-3 w-100" @click="$emit('close')">
                    Cancel
                </b-button>
            </div>
            <div class="col-6">
                <ButtonAction lg="true" block="true" :loading="loading" loadingTx="Uploading wallet" title="Create wallet" @click="onSubmit"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    data () {
        return {
            loading: false,
            form: {
                currency: null,
                currency_id: null
            }
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('wallet', ['getAssets', 'storeWallet']),
        getData () {
            this.loading = true
            this.getAssets().then(() => {
                this.loading = false
            })
        },
        onSubmit () {
            this.loading = true
            this.storeWallet(this.form).then(() => {
                openNotification()
                this.loading = false
                this.$emit('close')
            })
        },
        selectAsset(asset) {
            this.form.currency = asset.currency
            this.form.currency_id = asset.id
        }
    },
    computed: {
        ...mapState('wallet', ['assets'])
    }
}
</script>
<style scoped>
    .active{
        background: linear-gradient(256.2deg, #2D0D7C 0%, #3A0C4C 97.56%) !important;
        transition: .2s all;
    }
    .active .card-text div{
        color: white !important;
    }
</style>